<template>
  <div id="main" style="overflow: auto">
    <el-header
      ><kafkaSelect @kafkaChange="kafkaChange"></kafkaSelect
    ></el-header>
    <el-main>
      <el-row :gutter="10">
        <el-col :span="4">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="light"
            height="700"
            v-loading="loading"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection"> </el-table-column>
            <el-table-column prop="from" label="topic"> </el-table-column>
            <el-table-column prop="source" label="数据源" show-overflow-tooltip>
            </el-table-column> </el-table
        ></el-col>
        <el-col style="margin-left: -80px" :span="20"
          ><div id="mountNode" class="right"></div
        ></el-col>
      </el-row>
    </el-main>
  </div>
</template>

<script>
import kafkaSelect from "../kafkaSelect.vue";
import dataTag from "../common/dataTag.vue";
import GroupTable from "../common/GroupTable.vue";
import G6 from "@antv/g6";
export default {
  name: "topic",
  data() {
    return {
      id: undefined,
      tableData: [],
      // 图的数据
      graph: undefined,
      loading: false,
      height: 720,
      width: 0,
    };
  },
  mounted() {
    // this.getAllTopicDetail();
  },
  methods: {
    initG6() {
      if (this.graph) {
        this.graph.destroy();
      }
      this.width = document.getElementById("mountNode").scrollWidth;
      // this.height = document.getElementById("main").scrollWidth;
      const tooltip = new G6.Tooltip({
        offsetX: 20,
        offsetY: -20,
        // the types of items that allow the tooltip show up
        // 允许出现 tooltip 的 item 类型
        itemTypes: ["node", "edge"],
        // custom the tooltip's content
        // 自定义 tooltip 内容
        getContent: (e) => {
          const outDiv = document.createElement("div");
          outDiv.style.width = "fit-content";
          // outDiv.style.padding = '0px 0px 20px 0px';
          outDiv.style.margin = "0px 0px 0px 0px";
          outDiv.style.fontSize = "18px";
          if (e.item.getModel().source) {
            outDiv.innerHTML = `
            <ul style="list-style: none;">
              <li>数据源: ${e.item.getModel().source}</li>
            </ul>
            <ul style="list-style: none;">
              <li>topic: ${e.item.getModel().id}</li>
            </ul>`;
          } else {
            outDiv.innerHTML = `
            <ul style="list-style: none;">
              <li>流向: ${e.item.getModel().id}</li>
            </ul>`;
          }
          return outDiv;
        },
      });
      this.graph = new G6.Graph({
        container: "mountNode",
        // fitView: true,
        fitViewPadding: [40],
        // layout: {
        // type: "dagre",
        // rankdir: "LR", // 可选，默认为图的中心
        // align: "DL", // 可选
        // nodesep: 20, // 可选
        // ranksep: 50, // 可选
        // controlPoints: true, // 可选
        //   type: "force",
        //   preventOverlap: true,
        //   // nodeSize: 30,
        //   linkDistance: 300, // 指定边距离为100
        // },

        width: this.width,
        height: this.height,
        defaultEdge: {
          type: "quadratic",
          // type: "line",
          style: {
            stroke: "#BEBEBE",
            endArrow: true,
            // endArrow: {
            //   path: G6.Arrow.triangle(10, 20, 20), // 使用内置箭头路径函数，参数为箭头的 宽度、长度、偏移量（默认为 0，与 d 对应）
            //   d: 20,
            // },
            // startArrow: true,
            lineWidth: 2,
          },
        },
        defaultNode: {
          type: "circle",
          size: 30,
          style: { lineWidth: 3, stroke: "#545C64", fill: "#FFFFFF" },
          labelCfg: {
            position: "bottom",
            style: {
              fill: "#000",
              fontSize: 15,
            },
          },
        },
        plugins: [tooltip],
        // modes: {
        //   default: [
        //     {
        //       type: "tooltip", // 提示框
        //       size: 40,
        //       formatText(model) {
        //         // 提示框文本内容
        //         const text =
        //           "数据源: " + model.label + "<br/> topic: " + model.id;
        //         return text;
        //       },
        //     },
        //   ],
        // },
      });
    },
    async getAllTopicDetail() {
      this.loading = true;
      let { data } = await this.$http({
        url: this.$http.adornUrl("/kafka/getGraphList"),
        method: "get",
      });
      this.loading = false;
      // 初始化全部选中
      this.tableData = this.formatData(data.data);
      this.$refs.multipleTable.toggleAllSelection();
      // 画图
      this.initG6();
      let formatData = this.formatGraphData(this.tableData);
      this.graph.changeSize(this.width, this.height);
      this.graph.data(formatData); // 加载远程数据
      this.graph.render(); // 渲染
    },
    // 格式化后端传来的图数据
    formatData(data) {
      let newData = data.reduce((pre, cur) => {
        let index = pre.findIndex((item) => item.from == cur.from.slice(4));
        if (index == -1) {
          let obj = {
            from: undefined,
            source: undefined,
            to: [],
          };
          obj["from"] = cur.from.slice(4);
          obj["source"] = cur.source;
          obj["to"].push(cur.to);
          pre.push(obj);
        } else {
          pre[index].to.push(cur.to);
        }
        return pre;
      }, []);
      newData = newData.sort((a, b) => {
        return b.to.length - a.to.length;
      });
      return newData;
    },
    // 格式化图数据
    formatGraphData(data) {
      console.log("data :>> ", data);
      let axis = {
        left: {
          x: 200,
          y: 20,
        },
        right: {
          x: 900,
          y: 20,
        },
      };
      let count = 0;
      let flag = true;
      // 返回的数据
      let result = {
        nodes: [],
        edges: [],
      };
      for (let i = 0; i < data.length; i++, flag = !flag) {
        if (flag) {
          // 先确定from位置
          result.nodes.push({
            id: data[i].from,
            label: data[i].from,
            source: data[i].source,
            x: axis.left.x,
            y: axis.left.y + Math.trunc(data[i].to.length / 2) * 70,
            anchorPoints: [[0, 0.5]],
          });
          // 然后确定from点，添加边
          data[i].to.forEach((item) => {
            let str = this.getRandomString(4);
            result.nodes.push({
              id: item + str,
              label: item,
              x: axis.left.x + 500,
              y: axis.left.y,
              anchorPoints: [[0, 0.5]],
            });
            result.edges.push({
              source: data[i].from,
              target: item + str,
            });
            axis.left.y += 70;
            count++;
          });
          if (count > 10) {
            count -= 10;
            this.height += 720;
          }
        } else {
          // 先确定from位置
          result.nodes.push({
            id: data[i].from,
            label: data[i].from,
            source: data[i].source,
            x: axis.right.x,
            y: axis.right.y + Math.trunc(data[i].to.length / 2) * 70,
            anchorPoints: [[0, 0.5]],
          });
          // 然后确定from点，添加边
          data[i].to.forEach((item) => {
            let str = this.getRandomString(4);
            result.nodes.push({
              id: item + str,
              label: item,
              x: axis.right.x + 500,
              y: axis.right.y,
              anchorPoints: [[0, 0.5]],
            });
            result.edges.push({
              source: data[i].from,
              target: item + str,
            });
            axis.right.y += 70;
          });
        }
      }
      return result;
    },
    kafkaChange(id) {
      if (id == null || id == undefined) {
        return;
      }
      this.id = id;
      this.getAllTopicDetail();
    },
    getRandomString(length) {
      return Math.random().toString(36).slice(-length);
    },
    // 选择钩子
    handleSelectionChange(val) {
      console.log("val :>> ", val);
      // 重新绘图
      let result = this.formatGraphData(
        val.sort((a, b) => {
          return b.to.length - a.to.length;
        })
      );
      this.graph.data(result); // 加载远程数据
      this.graph.render(); // 渲染
    },
  },
  components: {
    kafkaSelect,
    dataTag,
    GroupTable,
  },
};
</script>

<style scoped>
/* div {
  margin-bottom: 5px 0;
} */
.header {
  display: flex;
}
.left {
  width: 400px;
  display: flex;
}
ul {
  list-style: none;
}
.el-table {
  font-size: 18px;
}
</style>
