<template>
  <el-select
    v-model="id"
    placeholder="选择集群环境"
    @change="handleChange"
    style="margin-bottom: 5px; width: 300px"
    clearable
  >
    <el-option
      v-for="item in tableData"
      :key="item.name"
      :label="item.name"
      :value="item.id"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  name: "kafkaSelect",
  data() {
    return {
      id: undefined,
      tableData: undefined,
    };
  },
  mounted() {
    this.getAllCluster();
  },
  methods: {
    async getAllCluster() {
      const { data } = await this.$http({
        url: this.$http.adornUrl("/kafka/getAllCluster"),
        methods: "get",
      });
      this.tableData = data.clusters;
    },
    handleChange() {
      this.$emit("kafkaChange", this.id);
    }
  },
};
</script>

<style scoped></style>
