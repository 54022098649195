<template>
  <div>
    <el-table :data="data" style="width: 100%">
        <el-table-column prop="topic" label="topic"> </el-table-column>
        <el-table-column prop="partition" label="分区号"> </el-table-column>
        <el-table-column prop="offset" label="消费者偏移量"> </el-table-column>
        <el-table-column prop="lag" label="未消费消息条数"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "GroupTablele",
  props: ['data'],

}
</script>

<style scoped>

</style>